












import { Component, Prop } from 'vue-property-decorator';
import VueBasePanelComponent from '@/components/panels/VueBasePanelComponent';

@Component
export default class PanelWideComponent extends VueBasePanelComponent {
  @Prop({ required: false, default: '8' })
  private readonly size!: string;

  get customSize(): string {
    let { size } = this;
    const num = Number(size);
    if (!this.size || Number.isNaN(num) || num > 8) {
      size = '8';
    }

    return `col-xl-${size}`;
  }
}
